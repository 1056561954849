import React from "react";
import Table from "react-bootstrap/lib/Table";
import Log from "./Log";
import LogContext from "./LogContext";
import Button from "@cx/ui/Button";
import TextInput from "@cx/ui/TextInput";
import SearchableSelect from "@cx/ui/SearchableSelect";
import { array, string, func } from "prop-types";
import "../testinvocation/Test.scss";
import "./Logs.scss";

const supportedLevels = ["TRACE", "DEBUG", "INFO", "WARN", "ERROR"];
const levelsOffByDefault = ["TRACE"];

export default class Logs extends React.Component {
  constructor(props) {
    super(props);

    const logLevels = {};
    supportedLevels.forEach(level => {
      logLevels[level] = !levelsOffByDefault.includes(level);
    });

    const sources = this.props.logs
      .map(log => {
        return log.source;
      })
      .filter(function(item, i, ar) {
        return ar.indexOf(item) === i;
      })
      .sort(function(a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });

    const expandStates = {};

    this.state = {
      expandStates,
      logLevels,
      sources,
      textFilter: "",
      sourceFilter: "",
      sourceSelectorValue: ""
    };

    if (
      props.highlightedLineNumber !== undefined &&
      props.highlightedLineNumber > 0 &&
      props.highlightedLineNumber <= this.props.logs.length
    ) {
      const highlightLog = this.props.logs[props.highlightedLineNumber - 1];
      logLevels[highlightLog.level] = true;
      if (
        highlightLog.context !== undefined ||
        highlightLog.errors !== undefined
      ) {
        expandStates[props.highlightedLineNumber] = true;
      }
    }

    this.logRef = React.createRef();
  }

  componentDidMount() {
    if (this.logRef.current !== null) {
      this.logRef.current.scrollIntoView();
    }
  }

  toggleDetails = id => {
    this.setState(preState => {
      preState.expandStates[id] = !preState.expandStates[id];
      return preState;
    });
  };

  toggleLogLevelFn = level => {
    return event => {
      this.setState(preState => {
        preState.logLevels[level] = !preState.logLevels[level];
        return preState;
      });
    };
  };

  renderLogToggles() {
    const toggles = [];
    supportedLevels.forEach(level => {
      const buttonStyle = this.state.logLevels[level] ? "primary" : "default";
      toggles.push(
        <Button
          htmlId={"toggle-log-button-" + level}
          size="sm"
          buttonStyle={buttonStyle}
          key={"toggle-" + level}
          onClick={this.toggleLogLevelFn(level)}
          className={"log-toggle"}
        >
          {level}
        </Button>
      );
    });
    return (
      <div className="span-group">
        <span className="span-left">Log Level Filter</span>
        <span className="span-right">{toggles}</span>
      </div>
    );
  }

  changeSourceFilter = (cxEvent, isValid, domEvent) => {
    const newFilter = [];
    Object.keys(cxEvent.target.value).forEach(optionId => {
      newFilter.push(cxEvent.target.value[optionId].value);
    });
    this.setState({
      sourceSelectorValue: cxEvent.target.value,
      sourceFilter: newFilter
    });
  };

  renderSourceFilter() {
    const options = [];
    this.state.sources.forEach(source => {
      options.push({
        value: source,
        label: source
      });
    });
    return (
      <div>
        <div className="span-group">
          <span className="span-left">Log Source Filter</span>
          <span className="span-right" />
        </div>
        <SearchableSelect
          htmlId="log-source-filter"
          label="Source Filter"
          name="searchableSelect"
          displayLabel={false}
          onChange={this.changeSourceFilter}
          options={options}
          value={this.state.sourceSelectorValue}
        />
      </div>
    );
  }

  onTextFilterChange = (cxEvent, isValid, domEvent) => {
    if (cxEvent.target.value !== undefined) {
      this.setState({
        textFilter: cxEvent.target.value
      });
    }
  };

  renderTextFilter() {
    return (
      <div>
        <div className="span-group">
          <span className="span-left">Log Text Filter</span>
          <span className="span-right" />
        </div>
        <div className="textFilter">
          <TextInput
            displayLabel={false}
            htmlId="log-filter"
            label="none"
            name="name"
            onChange={this.onTextFilterChange}
          />
        </div>
      </div>
    );
  }

  renderFilters() {
    return (
      <div className="log-filters">
        {this.renderLogToggles()}
        {this.renderSourceFilter()}
        {this.renderTextFilter()}
      </div>
    );
  }

  render() {
    const rows = [];
    let id = 1;
    let logsShown = 0;
    this.props.logs.forEach(log => {
      if (
        this.state.logLevels[log.level] === true &&
        (this.state.sourceFilter.length === 0 ||
          this.state.sourceFilter.includes(log.source)) &&
        (this.state.textFilter === "" ||
          JSON.stringify(log)
            .toLowerCase()
            .includes(this.state.textFilter.toLowerCase()))
      ) {
        const isSelected = this.props.highlightedLineNumber === id + "";
        const thisRef = isSelected ? this.logRef : undefined;
        rows.push(
          <Log
            scrollRef={thisRef}
            log={log}
            id={id}
            key={"log-line-" + id}
            onClick={this.toggleDetails}
            onNumberClick={this.props.processLogLineChange}
            highlight={isSelected}
          />
        );
        rows.push(
          <LogContext
            log={log}
            id={id}
            key={"log-details-" + id}
            show={this.state.expandStates[id]}
            highlight={isSelected}
          />
        );
        logsShown++;
      }
      id++;
    });
    const hiddenLogs = this.props.logs.length - logsShown;
    return (
      <div>
        <div className="container">{this.renderFilters()}</div>
        <Table className="log-table" responsive>
          <thead>
            <tr>
              <th>Line&nbsp;#</th>
              <th />
              <th>Timestamp</th>
              <th>Level</th>
              <th>Source</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <div className="remainingLogsMessage">
          {hiddenLogs === 0
            ? "All log lines are shown."
            : hiddenLogs + " log lines have been hidden due to filters..."}
        </div>
      </div>
    );
  }
}

Logs.propTypes = {
  highlightedLineNumber: string,
  logs: array.isRequired,
  processLogLineChange: func.isRequired
};
