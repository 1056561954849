import React from "react";
import PageHeader from "react-bootstrap/lib/PageHeader";
import { Link } from "react-router-dom";
import "./TestSuites.scss";
import { withOktaAuth } from "@okta/okta-react";
const api = require("../../api/api");

export default withOktaAuth(
  class TestSuites extends React.Component {
    static get propTypes() {
      return {
        authState: Object.isRequired,
        oktaAuth: Object.isRequired
      };
    }
    constructor(props) {
      super(props);
      this.state = {
        testSuitesData: null
      };
    }

    // returns a list of <li> elements representing parent.children
    generateNavDOM(parent) {
      const items = [];
      Object.keys(parent.children)
        .sort()
        .forEach(name => {
          const node = parent.children[name];
          const label =
            node.href === null ? (
              <span className="deadNode">{name}</span>
            ) : (
              <Link to={node.href}>{name}</Link>
            );
          if (Object.keys(node.children).length > 0) {
            const childListItems = this.generateNavDOM(node);
            items.push(
              <li key={name}>
                {label}
                <ul>{childListItems}</ul>
              </li>
            );
          } else {
            items.push(<li key={name}>{label}</li>);
          }
        });
      return items;
    }

    makeNavTree(suites) {
      const tree = { children: {} };
      suites.forEach(suiteId => {
        const parts = suiteId.split(".");
        let node = tree;
        parts.forEach(part => {
          if (node.children[part] === undefined) {
            node.children[part] = {
              href: null,
              children: {}
            };
          }
          node = node.children[part];
        });
        node.href = `/suite/${suiteId}`;
        node.hrefText = suiteId;
      });
      return tree;
    }

    renderContent(data) {
      const navItems = this.generateNavDOM(this.makeNavTree(data.items));
      return (
        <div className="container test-suites">
          <PageHeader>Test Suites</PageHeader>
          <div className="suitesNav">
            <ul>{navItems}</ul>
          </div>
          <div className="underline" />
        </div>
      );
    }

    render() {
      if (this.state.error) {
        return <pre>{JSON.stringify(this.state.error, null, "    ")}</pre>;
      }
      if (this.state.testSuitesData) {
        document.title = "Test Sweets";
        return this.renderContent(this.state.testSuitesData);
      } else {
        api
          .getTestSuites({
            authToken: this.props.authState.accessToken.accessToken,
            refreshAuthToken: this.props.oktaAuth.getRefreshToken
          })
          .then(response => {
            this.setState({
              testSuitesData: response.data
            });
          })
          .catch(error => {
            this.setState({
              error
            });
          });
        return <div />;
      }
    }
  }
);
