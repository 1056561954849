import React from "react";
import Header from "./header/Header";
import { Route, useHistory } from "react-router-dom";
import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import TestInvocation from "./testinvocation/TestInvocation";
import SingleTestDetails from "./singletest/SingleTestDetails";
import TestTrends from "./test-trends/TestTrends";
import TestSuite from "./testsuite/TestSuite";
import TestSuites from "./testsuites/TestSuites";

function getParam(key) {
  return new URLSearchParams(window.location.hash.replace(/^.*\?/, "")).get(
    key
  );
}

const HomePage = props => {
  const oktaAuth = new OktaAuth({
    issuer:
      "https://authorize.dangerzone.coxautoservices.com/oauth2/ausn8kjpghGoyBHIr0h7",
    clientId: "0oa15ou7s1eiV0u3D0h8",
    redirectUri: window.location.origin + "/login/callback"
  });
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Header />
      <SecureRoute
        exact
        path="/test-suites/id/:suiteId/invocations/id/:invocationId/test-results/id/:testId"
        render={({ match }) => (
          <SingleTestDetails
            match={match}
            highlightedLineNumber={getParam("line")}
          />
        )}
      />
      <SecureRoute
        exact
        path="/suite/:suiteId/invocation/:invocationId/test/:testId"
        render={({ match }) => (
          <SingleTestDetails
            match={match}
            highlightedLineNumber={getParam("line")}
          />
        )}
      />
      <SecureRoute
        exact
        path="/suite/:suiteId/invocation/:invocationId"
        render={({ match }) => (
          <TestInvocation
            match={match}
            highlightedLineNumber={getParam("line")}
          />
        )}
      />
      <SecureRoute
        exact
        path="/suite/:suiteId/test-trends"
        render={({ match }) => <TestTrends match={match} />}
      />
      <SecureRoute
        exact
        path="/suite/:suiteId"
        render={({ match }) => <TestSuite match={match} />}
      />
      <Route path="/login/callback" render={() => <LoginCallback />} />
      <SecureRoute exact path="/" render={() => <TestSuites />} />
    </Security>
  );
};
export default HomePage;
