import React from "react";
import { Link } from "react-router-dom";
import "./BreadCrumbs.scss";
import { string } from "prop-types";

function getCrumbData(props) {
  const crumbData = [];
  crumbData.push({
    label: "Test Suites",
    url: ""
  });
  if (props.suiteId !== undefined) {
    crumbData.push({
      label: "Suite: " + props.suiteId,
      url: crumbData.slice(-1)[0].url + "/suite/" + props.suiteId
    });
    if (props.invocationId !== undefined) {
      crumbData.push({
        label: "Invocation: " + props.invocationId,
        url: crumbData.slice(-1)[0].url + "/invocation/" + props.invocationId
      });
      if (props.testResultId !== undefined) {
        crumbData.push({
          label: "Test: " + props.testResultName,
          url: crumbData.slice(-1)[0].url + "/test/" + props.testResultId
        });
      }
    }
  }
  if (props.subPage !== undefined) {
    crumbData.push({
      label: props.subPage,
      url: ""
    });
  }
  return crumbData;
}

function getCrumbs(props) {
  const crumbs = [];
  const crumbData = getCrumbData(props);
  for (let i = 0; i < crumbData.length; i++) {
    const crumb = crumbData[i];
    if (i > 0) {
      crumbs.push(
        <span className="spacer" key={i + "-span"}>
          /
        </span>
      );
    }
    if (i === crumbData.length - 1) {
      crumbs.push(
        <span className="page current" key={i}>
          {crumb.label}
        </span>
      );
    } else {
      crumbs.push(
        <span className="page previous" key={i}>
          <Link to={crumb.url}>{crumb.label}</Link>
        </span>
      );
    }
  }
  return crumbs;
}

const BreadCrumbs = props => {
  return (
    <div>
      <div className="bread-crumbs">{getCrumbs(props)}</div>
      <div className="underline" />
    </div>
  );
};

BreadCrumbs.propTypes = {
  invocationId: string,
  subPage: string,
  suiteId: string,
  testResultId: string,
  testResultName: string
};
export default BreadCrumbs;
