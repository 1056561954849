import { BrowserRouter } from "react-router-dom";
import React from "react";
import HomePage from "./HomePage";

const HomePageWithRouterAccess = () => (
  <BrowserRouter>
    <HomePage />
  </BrowserRouter>
);

export default HomePageWithRouterAccess;
