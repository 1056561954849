import React from "react";
import PageHeader from "react-bootstrap/lib/PageHeader";
import Table from "react-bootstrap/lib/Table";
import { Link } from "react-router-dom";
import BreadCrumbs from "../breadcrumbs/BreadCrumbs";
import FriendlyTimestamp from "../friendly-timestamp/FriendlyTimestamp";
import Button from "@cx/ui/Button";
import "./TestSuite.scss";
import { withOktaAuth } from "@okta/okta-react";
const api = require("../../api/api");
const utilities = require("../../api/utilities");

export default withOktaAuth(
  class TestSuite extends React.Component {
    static get propTypes() {
      return {
        match: Object.isRequired,
        authState: Object.isRequired,
        oktaAuth: Object.isRequired
      };
    }

    constructor(props) {
      super(props);
      this.state = {
        error: null,
        suiteId: props.match.params.suiteId,
        continuationToken: null,
        testInvocations: null
      };
    }

    requestData = () => {
      api
        .getTestInvocationsBySuite(
          this.props.match.params.suiteId,
          {
            authToken: this.props.authState.accessToken.accessToken,
            refreshAuthToken: this.props.oktaAuth.getRefreshToken
          },
          this.state.continuationToken
        )
        .then(response => {
          this.setState(function(preState, props) {
            const invocations = preState.testInvocations
              ? preState.testInvocations.concat(response.data.items)
              : response.data.items;
            return {
              continuationToken: response.data.continuationToken,
              testInvocations: invocations
            };
          });
        })
        .catch(error => {
          this.setState({
            error
          });
        });
    };
    renderContent() {
      const rows = [],
        suiteId = this.state.suiteId,
        nextToken = this.state.continuationToken;
      let div;
      if (nextToken) {
        div = (
          <Button
            className="horizontal-center-btn"
            htmlId="loadMore"
            onClick={this.requestData}
            size="lg"
          >
            Load More
          </Button>
        );
      } else {
        div = (
          <div className="horizontal-center-txt">No more test invocations</div>
        );
      }
      for (const key of Object.keys(this.state.testInvocations)) {
        const invocation = this.state.testInvocations[key];
        const duration = utilities.getDuration(
          invocation.startTimestamp,
          invocation.endTimestamp
        );
        const invocationUrl = `/suite/${suiteId}/invocation/${invocation.invocationId}`;
        const className = `${invocation.status}-test`;
        rows.push(
          <tr key={key}>
            <td>
              <FriendlyTimestamp timestamp={invocation.timestamp} />
            </td>
            <td>{duration}</td>
            <td>
              <Link to={invocationUrl}>{invocation.invocationId}</Link>
            </td>
            <td className={className}>{invocation.status}</td>
            <td className="invoker">{invocation.invoker}</td>
          </tr>
        );
      }
      return (
        <div className="container test-suite">
          <BreadCrumbs suiteId={this.state.suiteId} />
          <PageHeader>Test Suite: {this.state.suiteId}</PageHeader>
          <p>
            See:
            <Link to={"/suite/" + suiteId + "/test-trends/"}>
              Test Status Trends
            </Link>
          </p>
          <Table responsive>
            <thead>
              <tr>
                <th>Started</th>
                <th>Duration</th>
                <th>Invocation ID</th>
                <th>Status</th>
                <th>Invoker</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
          {div}
        </div>
      );
    }

    render() {
      if (this.state.error) {
        return <pre>{JSON.stringify(this.state.error, null, "    ")}</pre>;
      }
      if (this.state.testInvocations) {
        document.title = this.state.suiteId;
        return this.renderContent();
      } else {
        this.requestData();
        return <div />;
      }
    }
  }
);
