import React from "react";
import PageHeader from "react-bootstrap/lib/PageHeader";
import BreadCrumbs from "../breadcrumbs/BreadCrumbs";
import Popover from "@cx/ui/Popover";
import FriendlyTimestamp from "../friendly-timestamp/FriendlyTimestamp";
import Logs from "../logs/Logs";
import "./SingleTest.scss";
import { withOktaAuth } from "@okta/okta-react";
const api = require("../../api/api");
const utilities = require("../../api/utilities");

export default withOktaAuth(
  class SingleTestDetails extends React.Component {
    static get propTypes() {
      return {
        highlightedLineNumber: String,
        match: Object.isRequired,
        authState: Object.isRequired,
        oktaAuth: Object.isRequired
      };
    }

    constructor(props) {
      super(props);
      this.state = {
        suiteId: props.match.params.suiteId,
        invocationId: props.match.params.invocationId,
        testId: props.match.params.testId,
        highlightedLineNumber: props.highlightedLineNumber
      };
    }

    renderContent(data) {
      const testStatusSpanClassName = `span-right ${data.summary.status}-test`;
      const duration = utilities.getDuration(
        data.startedTimestamp,
        data.completedTimestamp
      );

      const stageElements = [];
      if (data.summary.stageResults !== undefined) {
        data.summary.stageResults.forEach(stageResult => {
          stageElements.push(
            <div>
              <span className="stageName">
                <Popover
                  htmlId={"stage-pop-" + stageResult.stageName}
                  className="stage-desc-popover"
                  popoverContent={
                    "Description: " + stageResult.stageDescription
                  }
                  position="right"
                >
                  {stageResult.stageName}
                </Popover>
              </span>
              <span className="stageArrow">&nbsp;&rarr;&nbsp;</span>
              <span className={"stageResult " + stageResult.status}>
                {stageResult.status}
              </span>
            </div>
          );
        });
      } else {
        stageElements.push(<span>None found.</span>);
      }

      return (
        <div>
          <div className="container">
            <BreadCrumbs
              suiteId={this.state.suiteId}
              invocationId={this.state.invocationId}
              testResultId={this.state.testId}
              testResultName={data.summary.testName}
            />
            <PageHeader>Test: {data.summary.testName}</PageHeader>
            <div className="underline" />
            <div className="span-group">
              <span className="span-left">Test Description</span>
              <span className="span-right">{data.summary.testDescription}</span>
            </div>
            <div className="span-group">
              <span className="span-left">Invocation ID</span>
              <span className="span-right">{data.invocationId}</span>
            </div>
            <div className="span-group">
              <span className="span-left">Test Suite ID</span>
              <span className="span-right">{data.testSuiteId}</span>
            </div>
            <div className="span-group">
              <span className="span-left">Test Status</span>
              <span className={testStatusSpanClassName}>
                {data.summary.status}
              </span>
            </div>
            <div className="span-group">
              <span className="span-left">Stage Results</span>
              <span className="span-right">
                <div className="stageResults">{stageElements}</div>
              </span>
            </div>
            <div className="span-group">
              <span className="span-left">Invoker</span>
              <span className="span-right">{data.invoker}</span>
            </div>
            <div className="span-group">
              <span className="span-left">Started</span>
              <span className="span-right">
                <FriendlyTimestamp timestamp={data.startedTimestamp} />
              </span>
            </div>
            <div className="span-group">
              <span className="span-left">Completed</span>
              <span className="span-right">
                <FriendlyTimestamp timestamp={data.completedTimestamp} />
              </span>
            </div>
            <div className="span-group">
              <span className="span-left">Duration</span>
              <span className="span-right">{duration}</span>
            </div>
            <div className="span-group">
              <span className="span-left">Result Message</span>
              <span className="span-right keepNewLines">
                {data.summary.statusReason}
              </span>
            </div>
            <div className="underline" />
          </div>
          <div className="logTableContainer">
            <Logs
              logs={data.logs}
              highlightedLineNumber={this.state.highlightedLineNumber}
              processLogLineChange={this.processLogLineChange}
            />
          </div>
        </div>
      );
    }

    processLogLineChange = newHighlightedLineNumber => {
      if (this.state.highlightedLineNumber !== newHighlightedLineNumber) {
        this.setState({
          highlightedLineNumber: newHighlightedLineNumber
        });
      }
    };

    render() {
      if (this.state.error) {
        return <pre>{JSON.stringify(this.state.error, null, "    ")}</pre>;
      }
      if (this.state.testResult) {
        document.title =
          "Test " +
          this.state.testResult.summary.status +
          ": " +
          this.state.testResult.summary.testName;
        return this.renderContent(this.state.testResult);
      } else {
        api
          .getSingleTestById(
            this.state.suiteId,
            this.state.invocationId,
            this.state.testId,
            {
              authToken: this.props.authState.accessToken.accessToken,
              refreshAuthToken: this.props.oktaAuth.getRefreshToken
            }
          )
          .then(response => {
            this.setState({
              testResult: response.data
            });
          })
          .catch(error => {
            this.setState({
              error
            });
          });
        return <div />;
      }
    }
  }
);
