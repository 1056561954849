const axios = require("axios"),
  apiEndpoint = "https://test-sweets.core-domain-np.coxautoservices.com";
// apiEndpoint = "http://localhost:8090";

function apiCall(url, user, retriable) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${user.authToken}`;
  return axios
    .get(url)
    .then(response => {
      return response;
    })
    .catch(error => {
      // token might be expired
      if (error.response.status === 401) {
        if (retriable) {
          const refreshedUser = user.refreshAuthToken();
          return apiCall(url, refreshedUser, false); // only retry once
        } else {
          throw new Error("reached the maximum retries, give up", error);
        }
      }
      throw error;
    });
}

function getTestSuites(user) {
  const url = `${apiEndpoint}/test-suites`;
  return apiCall(url, user, true);
}

function getTestInvocationsBySuite(suiteId, user, continuationToken) {
  let url = `${apiEndpoint}/test-suites/${suiteId}/invocations`;
  if (continuationToken) {
    url += `?continuationToken=${encodeURIComponent(continuationToken)}`;
  }
  return apiCall(url, user, true);
}

function getTestInvocationById(suiteId, invocationId, user) {
  const url = `${apiEndpoint}/test-suites/${suiteId}/invocations/${invocationId}`;
  return apiCall(url, user, true);
}

function getSingleTestById(suiteId, invocationId, testId, user) {
  const url = `${apiEndpoint}/test-suites/${suiteId}/invocations/${invocationId}/test-results/${testId}`;
  return apiCall(url, user, true);
}

module.exports = {
  getTestSuites,
  getTestInvocationsBySuite,
  getTestInvocationById,
  getSingleTestById
};
