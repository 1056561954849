import React from "react";
import PageHeader from "react-bootstrap/lib/PageHeader";
import Table from "react-bootstrap/lib/Table";
import { Link } from "react-router-dom";
import BreadCrumbs from "../breadcrumbs/BreadCrumbs";
import FriendlyTimestamp from "../friendly-timestamp/FriendlyTimestamp";
import Button from "@cx/ui/Button";
import "./TestTrends.scss";
import { withOktaAuth } from "@okta/okta-react";
const api = require("../../api/api");

export default withOktaAuth(
  class TestTrends extends React.Component {
    static get propTypes() {
      return {
        match: Object.isRequired,
        authState: Object.isRequired,
        oktaAuth: Object.isRequired
      };
    }

    constructor(props) {
      super(props);
      this.state = {
        error: null,
        suiteId: props.match.params.suiteId,
        continuationToken: null,
        testInvocations: null
      };
    }

    requestData = () => {
      const userObj = {
        authToken: this.props.authState.accessToken.accessToken,
        refreshAuthToken: this.props.oktaAuth.getRefreshToken
      };
      api
        .getTestInvocationsBySuite(
          this.props.match.params.suiteId,
          userObj,
          this.state.continuationToken
        )
        .then(response => {
          const invocations = response.data.items;
          const invocationCalls = [];
          invocations.forEach(invocation => {
            invocationCalls.push(
              api
                .getTestInvocationById(
                  this.props.match.params.suiteId,
                  invocation.invocationId,
                  userObj
                )
                .then(response => {
                  invocation.invocationDetails = response.data;
                  return;
                })
            );
          });
          return Promise.all(invocationCalls).then(x => {
            return {
              invocations,
              continuationToken: response.data.continuationToken
            };
          });
        })
        .then(hydratedResponse => {
          this.setState(function(preState, props) {
            const invocations = preState.testInvocations
              ? preState.testInvocations.concat(hydratedResponse.invocations)
              : hydratedResponse.invocations;
            return {
              continuationToken: hydratedResponse.continuationToken,
              testInvocations: invocations
            };
          });
        })
        .catch(error => {
          this.setState({
            error
          });
        });
    };

    renderContent() {
      const columns = {}; // used to make sure each invocation has headers for all known tests and stages, map of testId -> array of stage names
      this.state.testInvocations.forEach(invocation => {
        for (const testId of Object.keys(
          invocation.invocationDetails.testResultSummaries
        )) {
          const test = invocation.invocationDetails.testResultSummaries[testId];
          if (columns[testId] === undefined) {
            columns[testId] = [];
          }
          if (test.stageResults !== undefined) {
            test.stageResults.forEach(stage => {
              if (!columns[testId].includes(stage.stageName)) {
                columns[testId].push(stage.stageName);
              }
            });
          }
        }
      });
      const testIds = Object.keys(columns); // sorted test ids for iterating through the above columbs obj
      testIds.sort();

      const rows = [],
        suiteId = this.state.suiteId,
        nextToken = this.state.continuationToken;
      let moreItems;
      if (nextToken) {
        moreItems = (
          <Button
            className="horizontal-center-btn"
            htmlId="loadMore"
            onClick={this.requestData}
            size="lg"
          >
            Load More
          </Button>
        );
      } else {
        moreItems = (
          <div className="horizontal-center-txt">No more test invocations</div>
        );
      }
      for (const key of Object.keys(this.state.testInvocations)) {
        const invocation = this.state.testInvocations[key];
        const summaries = invocation.invocationDetails.testResultSummaries;
        const invocationUrl = `/suite/${suiteId}/invocation/${invocation.invocationId}`;
        const className = `${invocation.status}-test`;

        const extraCols = [];

        testIds.forEach(testId => {
          const summary = summaries[testId];
          const testStatus = summary === undefined ? "UNKNOWN" : summary.status;
          // status
          extraCols.push(
            <td className={testStatus + " testStatus"}>
              <div className="statusTooltip">
                {"Test: " + testId + " = " + testStatus}
              </div>
              &nbsp;
              <Link to={invocationUrl + "/test/" + testId}>⮕</Link>
            </td>
          );
          for (const stageName of columns[testId]) {
            let stageStatus = "UNKNOWN";
            if (summary !== undefined && summary.stageResults !== undefined) {
              const stage = summary.stageResults.find(
                s => s.stageName === stageName
              );
              if (stage !== undefined && stage.status !== undefined) {
                stageStatus = stage.status;
              }
            }
            const stageTooltip = (
              <div className="statusTooltip">
                {"Stage: " + testId + " / " + stageName + " = " + stageStatus}
              </div>
            );
            // stageName & status
            extraCols.push(
              <td className={stageStatus + " stageStatus"}>{stageTooltip}</td>
            );
          }
        });

        rows.push(
          <tr key={key}>
            <td className="sticky">
              <FriendlyTimestamp timestamp={invocation.timestamp} />
            </td>
            <td>
              <Link to={invocationUrl}>{invocation.invocationId}</Link>
            </td>
            <td className="invoker">{invocation.invoker}</td>
            <td className={className}>{invocation.status}</td>
            {extraCols}
          </tr>
        );
      }

      const extraHeaders = [];
      for (const testId of testIds) {
        extraHeaders.push(
          <th className="rotated testId testCol">
            <span>{testId}</span>
          </th>
        );
        for (const stageName of columns[testId]) {
          extraHeaders.push(
            <th className="rotated stageName">
              <span>{stageName}</span>
            </th>
          );
        }
      }
      return (
        <div>
          <div className="container test-suite">
            <BreadCrumbs suiteId={this.state.suiteId} subPage="Test Trends" />
            <PageHeader>Test Suite: {this.state.suiteId}</PageHeader>
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th className="sticky">Started</th>
                <th>Invocation ID</th>
                <th>Invoker</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
          {moreItems}
        </div>
      );
    }

    render() {
      if (this.state.error) {
        return <pre>{JSON.stringify(this.state.error, null, "    ")}</pre>;
      }
      if (this.state.testInvocations) {
        document.title = this.state.suiteId;
        return this.renderContent();
      } else {
        this.requestData();
        return <div>Loading...</div>;
      }
    }
  }
);
