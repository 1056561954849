import React from "react";
import { Link } from "react-router-dom";
import { number, func, object, bool } from "prop-types";
import IconMenuRight from "@cx/ui/Icons/IconMenuRight";
import IconMenuDown from "@cx/ui/Icons/IconMenuDown";
import "../testinvocation/Test.scss";

export default class Log extends React.Component {
  constructor(props) {
    super(props);
    let expandState = false;
    if (
      this.props.highlight &&
      (this.props.log.context !== undefined ||
        this.props.log.errors !== undefined)
    ) {
      expandState = true;
    }
    let headerSize = undefined;
    if (
      this.props.log.context !== undefined &&
      this.props.log.context["::test-sweets:header"] !== undefined
    ) {
      headerSize = this.props.log.context["::test-sweets:header"];
    }
    this.state = {
      expand: expandState,
      headerSize
    };
  }

  toggleContext = event => {
    this.setState(preState => {
      preState.expand = !preState.expand;
      return preState;
    });
    this.props.onClick(this.props.id);
  };

  render() {
    const log = this.props.log,
      toggleIcon = this.state.expand ? (
        <IconMenuDown htmlId="IconMenuDown" />
      ) : (
        <IconMenuRight htmlId="IconMenuRight" />
      );
    let toggleTd;
    if (log.context === undefined && log.errors === undefined) {
      toggleTd = <td className="contextToggle" />;
    } else {
      toggleTd = (
        <td className="contextToggle clickable" onClick={this.toggleContext}>
          {toggleIcon}
        </td>
      );
    }
    const highlightClass = this.props.highlight ? " highlighted" : "";
    const link = this.props.highlight ? "?" : "?line=" + this.props.id;
    const numberClickArg = this.props.highlight ? "" : this.props.id + "";
    const headerClasses =
      this.state.headerSize === undefined
        ? ""
        : " log-header log-header-size-" + this.state.headerSize;
    return (
      <tr
        className={
          "level-" + log.level + " log-line" + highlightClass + headerClasses
        }
        id={"log-line-" + this.props.id}
        ref={this.props.scrollRef}
      >
        <td className="logNumber">
          <Link
            to={link}
            onClick={() => {
              this.props.onNumberClick(numberClickArg);
            }}
          >
            {this.props.id}
          </Link>
        </td>
        {toggleTd}
        <td className="timestamp">{log.timestamp}</td>
        <td className="level">{log.level}</td>
        <td className="source">{log.source}</td>
        <td className="message wrapContext">{log.message}</td>
      </tr>
    );
  }
}

Log.propTypes = {
  highlight: bool,
  id: number,
  log: object.isRequired,
  onClick: func,
  onNumberClick: func.isRequired,
  scrollRef: object
};
