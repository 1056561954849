import React from "react";
import PageHeader from "react-bootstrap/lib/PageHeader";
import Table from "react-bootstrap/lib/Table";
import SingleTestRow from "./SingleTestRow";
import Logs from "../logs/Logs";
import "./Test.scss";
import BreadCrumbs from "../breadcrumbs/BreadCrumbs";
import FriendlyTimestamp from "../friendly-timestamp/FriendlyTimestamp";
import { withOktaAuth } from "@okta/okta-react";
const api = require("../../api/api");
const utilities = require("../../api/utilities");

export default withOktaAuth(
  class TestInvocation extends React.Component {
    static get propTypes() {
      return {
        highlightedLineNumber: String,
        match: Object.isRequired,
        authState: Object.isRequired,
        oktaAuth: Object.isRequired
      };
    }

    constructor(props) {
      super(props);
      this.state = {
        suiteId: props.match.params.suiteId,
        invocationId: props.match.params.invocationId,
        highlightedLineNumber: props.highlightedLineNumber
      };
    }

    renderContent = data => {
      const rows = [],
        testStatusSpanClassName = `span-right ${data.status}-test`;
      const duration = utilities.getDuration(
        data.startedTimestamp,
        data.completedTimestamp
      );
      const keys = Object.keys(data.testResultSummaries);
      keys.sort(function(a, b) {
        const nameA = data.testResultSummaries[a].testName;
        const nameB = data.testResultSummaries[b].testName;
        return nameA.localeCompare(nameB);
      });
      for (const key of keys) {
        const summary = data.testResultSummaries[key];
        rows.push(
          <SingleTestRow
            key={"test-" + key}
            suiteId={data.testSuiteId}
            invocationId={data.invocationId}
            testId={key}
            testResult={summary}
          />
        );
      }

      return (
        <div>
          <div className="container test-invocation">
            <BreadCrumbs
              suiteId={this.state.suiteId}
              invocationId={this.state.invocationId}
            />
            <PageHeader>Test Invocation: {data.invocationId}</PageHeader>
            <div className="underline" />
            <div className="span-group">
              <span className="span-left">Test Suite ID</span>
              <span className="span-right">{data.testSuiteId}</span>
            </div>
            <div className="span-group">
              <span className="span-left">Status</span>
              <span className={testStatusSpanClassName}>{data.status}</span>
            </div>
            <div className="span-group">
              <span className="span-left">Invoker</span>
              <span className="span-right">{data.invoker}</span>
            </div>
            <div className="span-group">
              <span className="span-left">Started</span>
              <span className="span-right">
                <FriendlyTimestamp timestamp={data.startedTimestamp} />
              </span>
            </div>
            <div className="span-group">
              <span className="span-left">Completed</span>
              <span className="span-right">
                <FriendlyTimestamp timestamp={data.completedTimestamp} />
              </span>
            </div>
            <div className="span-group">
              <span className="span-left">Duration</span>
              <span className="span-right">{duration}</span>
            </div>
            <div className="underline" />
            <Table responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Duration</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
            <PageHeader>Testing System Logs</PageHeader>
          </div>
          <div className="logTableContainer">
            {data.systemLogs !== undefined ? (
              <Logs
                logs={data.systemLogs}
                highlightedLineNumber={this.state.highlightedLineNumber}
                processLogLineChange={this.processLogLineChange}
              />
            ) : (
              <span>No logs available...</span>
            )}
          </div>
        </div>
      );
    };

    processLogLineChange = newHighlightedLineNumber => {
      if (this.state.highlightedLineNumber !== newHighlightedLineNumber) {
        this.setState({
          highlightedLineNumber: newHighlightedLineNumber
        });
      }
    };

    render() {
      if (this.state.error) {
        return <pre>{JSON.stringify(this.state.error, null, "    ")}</pre>;
      }
      if (this.state.invocationData) {
        document.title =
          "Invocation " +
          this.state.invocationData.status +
          ": " +
          this.state.invocationId.replace(/-.*/, "") +
          "...";
        return this.renderContent(this.state.invocationData);
      } else {
        api
          .getTestInvocationById(
            this.props.match.params.suiteId,
            this.props.match.params.invocationId,
            {
              authToken: this.props.authState.accessToken.accessToken,
              refreshAuthToken: this.props.oktaAuth.getRefreshToken
            }
          )
          .then(response => {
            this.setState({
              invocationData: response.data
            });
          })
          .catch(error => {
            this.setState({
              error
            });
          });
        return <div />;
      }
    }
  }
);
