import React from "react";
import { bool, object } from "prop-types";
import "../testinvocation/Test.scss";

const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
const linkRegex = /^https?:.*$/;

function renderContext(log, contents) {
  const context = log.context;
  if (context === undefined) {
    return;
  }
  const contextElements = [];
  for (const contextKey of Object.keys(context).sort()) {
    let contextValue = context[contextKey];
    let linkTip = null;
    if (typeof contextValue === "object") {
      contextValue = JSON.stringify(contextValue, undefined, "    ");
    }
    if (typeof contextValue === "string" && uuidRegex.test(contextValue)) {
      // is a UUID
      const millis = new Date(log.timestamp).getTime(),
        start = millis - 60 * 1000,
        end = millis + 60 * 1000,
        query = encodeURIComponent(
          "_sourceCategory=core-domain* AND " + contextValue
        ),
        url =
          "https://service.sumologic.com/ui/#section/search/@" +
          start +
          "," +
          end +
          "@" +
          query;
      linkTip = (
        <a href={url} target="_blank" rel="noopener noreferrer">
          Search for UUID in SumoLogic
        </a>
      );
    } else if (
      typeof contextValue === "string" &&
      linkRegex.test(contextValue)
    ) {
      // is a Link
      linkTip = (
        <a href={contextValue} target="_blank" rel="noopener noreferrer">
          Open Link
        </a>
      );
    }
    contextElements.push(
      <tr key={contextKey}>
        <td className="contextKey">
          <div>
            {contextKey}
            &nbsp;&nbsp;&nbsp;=
          </div>
        </td>
        <td className="contextValue">
          {linkTip !== null && <div className="linkTip">{linkTip}</div>}
          <pre>{contextValue}</pre>
        </td>
      </tr>
    );
  }
  contents.push(
    <table className={"context"} key="context-block">
      <tbody>{contextElements}</tbody>
    </table>
  );
}

function renderStackTrace(error) {
  let string = "";
  string += error.type + ": " + error.message + "\n";
  if (error.stacktrace !== undefined) {
    error.stacktrace.forEach(element => {
      string += "\t" + element + "\n";
    });
  }
  if (error.cause !== undefined) {
    string += "Caused by: " + renderStackTrace(error.cause);
  }
  return string;
}

function renderErrors(errors, contents) {
  if (errors === undefined) {
    return;
  }
  const errorElements = [];
  for (const errorId of Object.keys(errors)) {
    const error = errors[errorId];
    errorElements.push(
      <div key={errorId} className="errorStacktrace">
        <pre>{renderStackTrace(error)}</pre>
      </div>
    );
  }
  contents.push(<div className={"errors"}>{errorElements}</div>);
}

const LogContext = props => {
  const log = props.log;
  const contents = [];
  renderContext(log, contents);
  renderErrors(log.errors, contents);
  const trClass = props.show ? "" : "hidden";
  const highlightClass = props.highlight ? " highlighted" : "";
  return (
    <tr
      className={
        "level-" + log.level + " log-details " + trClass + highlightClass
      }
    >
      <td colSpan="8">{contents}</td>
    </tr>
  );
};

LogContext.propTypes = {
  highlight: bool,
  log: object.isRequired,
  show: bool
};

export default LogContext;
