import React from "react";
import { Link } from "react-router-dom";
import { object, string } from "prop-types";
const utilities = require("../../api/utilities");

const SingleTestRow = props => {
  const testResult = props.testResult,
    className = `${testResult.status}-test`,
    testResultUrl = `/suite/${props.suiteId}/invocation/${props.invocationId}/test/${props.testId}`,
    duration = utilities.getDuration(
      testResult.startedTimestamp,
      testResult.completedTimestamp
    );
  return (
    <tr>
      <td>
        <b>
          <Link to={testResultUrl}>{testResult.testName}</Link>
        </b>
        <div>
          <span>{testResult.testDescription}</span>
        </div>
      </td>
      <td className={className}>{testResult.status}</td>
      <td>{duration}</td>
      <td className="test-status-reason">{testResult.statusReason}</td>
    </tr>
  );
};

SingleTestRow.propTypes = {
  invocationId: string.isRequired,
  suiteId: string.isRequired,
  testId: string.isRequired,
  testResult: object.isRequired
};

export default SingleTestRow;
