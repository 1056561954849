import React from "react";
import "./FriendlyTimestamp.scss";
import Popover from "@cx/ui/Popover";
import { string } from "prop-types";

export default class FriendlyTimestamp extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date(props.timestamp);
    const label = date.toLocaleString();
    const day = " - " + date.toLocaleString("en-US", { weekday: "long" });
    this.state = {
      timestamp: props.timestamp,
      label,
      day
    };
  }
  render() {
    return (
      <span className="timestamp">
        {this.state.label}
        <span className="utc-link">
          <Popover
            htmlId={"ts-pop-" + this.state.timestamp}
            className="utc-popover"
            popoverContent={"UTC: \n" + this.state.timestamp}
            position="right"
          >
            &nbsp;EST
          </Popover>
          {this.state.day}
        </span>
      </span>
    );
  }
}

FriendlyTimestamp.propTypes = {
  timestamp: string.isRequired
};
