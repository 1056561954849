function getDuration(startTs, endTs) {
  if (
    startTs === null ||
    endTs === null ||
    startTs === undefined ||
    endTs === undefined
  ) {
    return "";
  }
  const start = Date.parse(startTs);
  const end = Date.parse(endTs);
  const durationInSeconds = (end - start) / 1000;
  const minutes = parseInt(durationInSeconds / 60, 10);
  const seconds = parseInt(durationInSeconds % 60, 10);
  return minutes + "m" + (seconds < 10 ? "0" : "") + seconds + "s";
}

module.exports = {
  getDuration
};
